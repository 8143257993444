import React from 'react';

import { IProduct } from 'src/explore/types/shoppe';

import { Card } from '../../elements/Card';

import styles from './index.module.sass';

interface CarouselProps {
  analytics?: Record<string, string>;
  hideLove?: boolean;
  hideMerchant?: boolean;
  isWhitelabel?: boolean;
  linkParameters?: {
    [key: string]: string;
  };
  product: IProduct;
  recommId?: string;
}

export const Carousel = ({
  analytics,
  hideLove,
  hideMerchant,
  isWhitelabel,
  linkParameters,
  product,
  recommId,
}: CarouselProps ) => (
  <li className={`${styles.product} flex-shrink-0 p-1 px-md-2 spec__product`}>
    <Card
      analytics={analytics}
      hideLove={hideLove}
      hideMerchant={hideMerchant}
      isWhitelabel={isWhitelabel}
      linkParameters={linkParameters}
      product={product}
      recommId={recommId}
      size="small"
    />
  </li>
);
