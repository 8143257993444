import React, { ReactNode } from 'react';
import cls from 'classnames';

import { IProduct } from 'src/explore/types/shoppe';

import { Card } from '../../elements/Card';

import styles from './index.module.sass';

interface DefaultProps {
  across?: number;
  hideLove?: boolean;
  hideMerchant?: boolean;
  isWhitelabel: boolean;
  linkParameters?: {
    [key: string]: string;
  };
  product: IProduct;
  recommId?: string;
  size?: 'small';
  onBellowContentRender?: ( product: IProduct ) => ReactNode;
  onClassName?: ( product: IProduct ) => string;
}

export const Default = ({
  across,
  hideLove,
  hideMerchant,
  isWhitelabel,
  linkParameters,
  product,
  recommId,
  size,
  onBellowContentRender,
  onClassName,
}: DefaultProps ) => (
  <li
    className={cls( 'flex-shrink-0 pt-1 px-1 pb-9 px-md-2 spec__product', styles.product, {
      [styles[`product--${across}-across`]]: !!across,
    })}
  >
    <div className="position-relative">
      <Card
        className={{
          container: onClassName && onClassName( product ),
        }}
        hideLove={hideLove}
        hideMerchant={hideMerchant}
        isWhitelabel={isWhitelabel}
        linkParameters={linkParameters}
        product={product}
        recommId={recommId}
        showLocation
        showRating
        size={size}
      />

      {onBellowContentRender && onBellowContentRender( product )}
    </div>
  </li>
);
