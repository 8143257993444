import { Carousel, Default } from 'src/explore/elements/ProductCard';

export class CardFactory {
  create = ( type ) => {
    switch ( type ) {
      case 'carousel':
        return Carousel;
      default:
        return Default;
    }
  };
}
