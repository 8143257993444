import React from 'react';
import { ProductCard } from '@goldbely/explore-component-library';
import camelCaseKeys from 'camelcase-keys';
import { useDispatch } from 'react-redux';

import * as cartActionCreators from 'src/explore/compositions/Cart/actions';
import * as cartCartItemsActionCreators from 'src/explore/data/cart/cart_items/actions';
import useFavorite from 'src/explore/hooks/useFavorite';
import { useShopConfig } from 'src/explore/hooks/useShopConfig';
import { IProduct } from 'src/explore/types/shoppe';

interface CardProps {
  analytics?: Record<string, string>;
  className?: {
    badge?: string;
    container?: string;
    details?: string;
    name?: string;
  };
  hideLove?: boolean;
  hideMerchant?: boolean;
  isWhitelabel?: boolean;
  linkParameters?: {
    [key: string]: string;
  };
  product: IProduct;
  recommId?: string;
  showLocation?: boolean;
  showRating?: boolean;
  size?: 'small';
  target?: string;
}

export const Card = ({
  analytics,
  className = {},
  hideLove,
  hideMerchant,
  isWhitelabel,
  linkParameters,
  product,
  recommId,
  showLocation,
  showRating,
  size,
  target,
}: CardProps ) => {
  const config = camelCaseKeys( useShopConfig(), { deep: true });
  const dispatch = useDispatch();
  const [ isFavorite, toggleFavorite ] = useFavorite( product.id, recommId );

  const handleAddToCart = (
    event: React.FormEvent | React.KeyboardEvent<any> | React.MouseEvent | null
  ) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      cartCartItemsActionCreators.create({
        ...linkParameters,
        cart_item: {
          product_id: product.id,
          quantity: product.defaultQuantity,
        },
      })
    );
    dispatch( cartActionCreators.open());
  };

  return (
    <ProductCard
      analytics={analytics}
      className={className}
      hideLove={hideLove}
      hideMerchant={hideMerchant}
      isFavorite={isFavorite}
      isWhitelabel={isWhitelabel}
      linkParameters={linkParameters}
      product={product as any} // TODO: cleanup IProduct
      showLocation={showLocation}
      showRating={showRating}
      size={size}
      soldOutLabel={config.ui?.soldOutLabel}
      target={target}
      onAddToCart={handleAddToCart}
      onClickFavorite={isWhitelabel ? null : toggleFavorite}
    />
  );
};
